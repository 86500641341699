import { post, get,post1 ,get2} from '../utils/axios';

/**
* 用户登陆
* @param mobile string 手机号
* @param password  string 密码
*/
export const login = params => { return post1('/designpatent/sys/login', params);};

/**
* 获取验证码
* @param mobile string 手机号
*/
export const getCode = params => { return get('/designpatent/sys/sms', params);};

/**
* 用户注册
* @param mobile string 手机号
*/
export const regis = params => { return post1('/designpatent/sys/register', params);};

/**
* 修改密码
* @param mobile string 手机号
*/
export const setPassword = params => { return post1('/designpatent/sys/forgetPassword', params);};

/**
* 图片检索
* @param 表单对象
*/
export const queryByKeyword = params => { return get('/designpatent/cnipr/queryByKeyword', params);};

/**
* 历史检索图片
*/
export const queryHistoryPic = () => { return get('/designpatent/history/queryHistoryPic');};

/**
* 专利详情
*/
export const designPatentDetail = params => { return get('/designpatent/cnipr/designPatentDetail',params);};

/**
* 用户基本信息
*/
export const basicInfo = () => { return get('/designpatent/basic/basicInfo');};

/**
* 用户历史检索信息
*/
export const queryHistoryInfo = params => { return get('/designpatent/basic/queryHistoryInfo',params);};

/**
* 删除用户历史检索信息
*/
export const delHistoryInfo = params => { return get('/designpatent/basic/delHistoryInfo',params);};

/**
* 修改密码
*/
export const updatePassword = params => { return post1('/designpatent/basic/updatePassword',params);};

/**
* 退出登录
*/
export const logout = () => { return post1('/designpatent/sys/logout');};

/**
* 登录前验证
* @param 表单对象
*/
export const checkLoginProtection = params => { return post1('/designpatent/sys/checkLoginProtection', params);};


/**
* 申请使用
* @param 表单对象
*/
export const submitEvidenceApply = params => { return post1('/designpatent/sys/submitEvidenceApply', params);};

/**
* 开户
* @param 表单对象
*/
export const submitOpenAccountApply = params => { return post1('/designpatent/sys/submitOpenAccountApply', params);};

/**
* 收藏列表查询
* @param 
*/
export const queryCollectionInfo = params => { return get('/designpatent/basic/queryCollectionInfo', params);};

/**
* 收藏/取消收藏
* @param 
*/
export const collectPatent = params => { return post('/designpatent/cnipr/collectPatent', params);};

/**
* 收藏列表查询下拉选项
* @param 
*/
export const collectionCondition = params => { return post('/designpatent/basic/collectionCondition', params);};

/**
* 数据更新
* @param 
*/
export const dataSourceList = () => { return get('/designpatent/basic/dataSourceList');};

/**
* 产品分类推荐
* @param 
*/
export const locarnoClsRecommend = params => { return get('/designpatent/cnipr/locarnoClsRecommend',params);};

/**
* 校验是否过期
* @param 
*/
export const checkAccount = () => { return get('/designpatent/cnipr/checkAccount');};



/**
* 选中数据
* @param 
*/
export const selckdata = (param) => {return get2('/designpatent/cnipr/exportSearchList',param);};


/**
* 选中数据
* @param 
*/
export const collection = (param) => {return post('/designpatent/cnipr/collectionSearchCondition',param);};