/**
 * 配置编译环境和线上环境之间的切换
 * 
 * baseURL: 域名地址
 * baseImgPath: 图片存放地址
 * 
 */
 let baseURL = 'https://dip.ipsebe.com';
 let baseImgPath = "https://dip.ipsebe.com/designpatent/file/uploadPic";
 

 if(location.host.indexOf('localhost') === 0){
   baseURL = 'http://192.168.1.211:16003/'
   baseImgPath = "http://192.168.1.211:16003/designpatent/file/uploadPic"
 }if(location.host.indexOf('192.168.1.211') === 0){
   baseURL = 'http://192.168.1.211:16003/'
   baseImgPath = "http://192.168.1.211:16003/designpatent/file/uploadPic"
 }else if(location.host.indexOf('test') === 0) {
   baseURL = 'https://testdip.ipsebe.com'
   baseImgPath = "https://testdip.ipsebe.com/designpatent/file/uploadPic"
 }
 export {
   baseURL,
   baseImgPath,
 }