import axios from 'axios';
import router from '@/router/index.js';
import qs from 'qs';
import { getToken } from './auth.js';
import { Message } from 'element-ui'
import { baseURL } from './env.js';

// 根地址、请求超时配置
export const request = axios.create({
    baseURL,
    timeout: 60000
});

// 请求头配置
request.interceptors.request.use(
    config => {
        config.url = `${config.baseURL}${config.url}`;
        // config.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';//??要不要配置
        if (getToken()) {
            config.headers['X-Access-Token'] = getToken();
        }


        return config;
    },
    error => {
        console.log(error);
        return Promise.reject(error);
    });

// 响应拦截
request.interceptors.response.use(
    response => {
        const res = response.data;
        // 失败错误处理
        if(res.code){
            if(res.code === 406){
                Message.error({
                    message: res.message,
                    onClose(){
                        router.push('/login');
                    },
                    duration: 1500
                });
                return false 
            }
            if(res.code === 404){
                Message.error(res.message);
                return false 
            }
            if(res.code === 500){
                Message.error(res.message);
                return false
            }
        }
        return Promise.resolve(response);
    },
    error => {
        // 请求失败处理
        Message.error(error.message);
        return Promise.reject(error);
    }
);

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params = {}) {
    return new Promise((resolve, reject) => {
        request({
            method: 'get',
            url: url,
            params: params,
        }).then((response) => {
            if (response.data) {
                resolve(response.data);
            }
        }).catch((error) => {
            reject(error);
        });
    });
}
export function get2(url, params = {}) {
    return new Promise((resolve, reject) => {
        
        request({
            method: 'get',
            url: url,
            params: params,
            responseType:"blob"
        }).then((response) => {
            if (response.data) {
                resolve(response);
            }
        }).catch((error) => {
            reject(error);
        });
    });
}
/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params = {}) {
    return new Promise((resolve, reject) => {
        request({
            url: url,
            method: 'post',
            data: qs.stringify(params)
        }).then(response => {
            if (response.data || response.status == 200) {

                resolve(response.data);
            }else{
                response.data = {
                    code:200,
                    msg:"错误"
                }
                resolve(response.data);
            }
        }).catch(error => {
            reject(error);
        });
    });
}
export function post1(url, params = {}) {
    return new Promise((resolve, reject) => {
        request({
            url: url,
            method: 'post',
            data: params
        }).then(response => {
            if (response.data) {
                resolve(response.data);
            }
        }).catch(error => {
            reject(error);
        });
    });
}
// 后续添加。。 如file文件流等
export default {
    get,
    post,
    post1
};
